import axios from '../../../../axios-auth'

const empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAtendentes() {
      return new Promise((resolve, reject) => {
        axios
          .get(`api/empresas/atendentes/${empresaID}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
