<template>
  <b-overlay
    :show="load"
    variant="transparent"
  >
    <section class="invoice-add-wrapper">
      <b-modal
        id="cadastrar-nova-entidade"
        :title="'Cadastrar nova ' + entidadeSelecionada"
        ok-only
        ok-title="cadastrar"
        @ok="cadastrar"
      >
        <b-col
          md="12"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="'Nova ' + entidadeSelecionada"
            label-for="basicInput"
          >
            <b-form-input
              v-if="entidadeSelecionada === 'marca'"
              id="basicInput"
              v-model="nomeMarca"
              placeholder="Digite aqui"
            />
            <b-form-input
              v-if="entidadeSelecionada === 'revenda'"
              id="basicInput"
              v-model="nomeRevenda"
              placeholder="Digite aqui"
            />
            <b-form-input
              v-if="entidadeSelecionada === 'linha'"
              id="basicInput"
              v-model="nomeLinha"
              placeholder="Digite aqui"
            />
            <b-form-input
              v-if="entidadeSelecionada === 'seguradora'"
              id="basicInput"
              v-model="nomeSeguradora"
              placeholder="Digite aqui"
            />
          </b-form-group>
        </b-col>
        <div v-if="sugestao">
          <vue-autosuggest
            :suggestions="filteredOptions"
            :limit="10"
            :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Digite aqui'}"
            @input="onInputChange"
            @selected="onSelected"
          >
            <template slot-scope="{suggestion}">
              <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
            </template>
          </vue-autosuggest>
        </div>

      </b-modal>
      <b-row class="invoice-add">

        <!-- Col: Left (Invoice Container) -->
        <b-col>
          <b-form @submit.prevent>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body>

                <div>

                  <!-- Header: Left Content -->
                  <div>
                    <div class="logo-wrapper">
                      <logo />
                      <h3 class="text-primary invoice-logo">
                        {{ empresaNome }}
                      </h3>
                    </div>
                  </div>

                  <!-- Header: Right Content -->
                  <b-row align-h="center">
                    <div class="d-flex flex-column flex-md-row justify-content-center">
                      <b-col
                        class="text-center mb-3 mb-md-0"
                        md="auto"
                      >
                        <div class="flex-grow-1">
                          <h4 class="invoice-title">
                            Sinistro
                          </h4>
                          <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                            <b-input-group-prepend is-text>
                              <feather-icon icon="HashIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              id="invoice-data-id"
                              v-model="invoice.sinistro"
                              type="number"
                            />
                          </b-input-group>
                        </div>
                      </b-col>

                      <b-col
                        class="text-center mb-3 mb-md-0"
                        md="auto"
                      >
                        <div class="flex-grow-1">
                          <span class="title">
                            *Data Abertura:
                          </span>
                          <flat-pickr
                            v-model="invoice.data_abertura"
                            class="form-control invoice-edit-input"
                          />
                        </div>
                      </b-col>

                      <b-col
                        class="text-center"
                        md="auto"
                        style="width: 320px;"
                      >
                        <div class="flex-grow-1">
                          <span class="title">
                            *Tipo Atendimento:
                          </span>
                          <v-select
                            v-model="invoice.tipo_atendimento"
                            :options="optionsAtendimento"
                          />
                        </div>
                      </b-col>
                    </div>
                  </b-row>
                </div>
              </b-card-body>
              <!-- Card Cliente --->
              <hr class="invoice-spacing">
              <b-card-body class="invoice-padding pt-0">
                <b-row>
                  <h6 class="mb-2">
                    Cliente:
                  </h6>
                  <b-form>
                    <b-row>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <label>*Nome</label>
                        <div
                          class="search-input"
                        >
                          <b-form-input
                            v-model="customer.nome"
                            :value="searchQuery"
                            autofocus
                            autocomplete="off"
                            @input="handleSearchInput"
                            @keyup.esc="showSearchBar = false, resetSearchQuery()"
                            @blur.stop="showSearchBar = false, resetSearchQuery()"
                          />
                          <vue-perfect-scrollbar
                            v-if="showSearchBar"
                            :settings="perfectScrollbarSettings"
                            class="search-list search-list-main scroll-area overflow-hidden"
                            :class="{'show': searchQuery}"
                            tagname="ul"
                          >
                            <li
                              v-for="(suggestion, index) in suggestions"
                              :key="index"
                              class="suggestion-group-suggestion cursor-pointer"
                              @mousedown.prevent="suggestionSelected(suggestion)"
                            >
                              <!-- Group Header -->
                              <p class="suggestion-group-title">
                                <span>
                                  {{ suggestion }}
                                </span>
                              </p>
                            </li>
                            <li
                              v-if="!suggestions.length && searchQuery"
                              class="suggestion-group-suggestion no-results"
                            >
                              <p>Nenhum dado encontrado.</p>
                            </li>
                          </vue-perfect-scrollbar>
                        </div>
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <b-form-group
                          label="*CPF/CNPJ"
                          label-for="CPF/CNPJ"
                        >
                          <cleave
                            v-if="customer.cpf_cnpj.length > 14"
                            id="customDelimiter"
                            v-model="customer.cpf_cnpj"
                            class="form-control"
                            :raw="false"
                            :options="{
                              numericOnly: true, ...mask.cnpj
                            }"
                            placeholder="CPF/CNPJ"
                          />
                          <cleave
                            v-else
                            id="customDelimiter"
                            v-model="customer.cpf_cnpj"
                            class="form-control"
                            :raw="false"
                            :options="{
                              numericOnly: true, ...mask.cpf
                            }"
                            placeholder="CPF/CNPJ"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <b-form-group
                          label="Telefone"
                          label-for="customer-telefone"
                        >
                          <b-form-input
                            id="customer-telefone"
                            v-model="customer.telefone"
                            trim
                            placeholder="Telefone"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <b-form-group
                          label="Email"
                          label-for="customer-email"
                        >
                          <b-form-input
                            id="customer-email"
                            v-model="customer.email"
                            trim
                            placeholder="Email"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <b-form-group
                          label="Endereco"
                          label-for="endereco"
                        >
                          <b-form-input
                            id="endereco"
                            v-model="customer.endereco"
                            placeholder="Endereço"
                            trim
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <b-form-group
                          label="CEP"
                          label-for="endereco"
                        >
                          <b-input-group>
                            <cleave
                              v-model="customer.cep"
                              name="cep"
                              class="form-control"
                              :raw="false"
                              :options="{numericOnly: true, ...mask.cep}"
                              placeholder="00000-000"
                              @keyup.enter="consultaCEP()"
                            />
                            <b-input-group-append>
                              <b-button
                                variant="outline-primary"
                                @click="consultaCEP()"
                              >
                                <feather-icon icon="SearchIcon" />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <b-form-group
                          label="Numero"
                          label-for="endereco"
                        >
                          <b-form-input
                            id="endereco"
                            v-model="customer.numero"
                            type="number"
                            placeholder="Número"
                            trim
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <b-form-group
                          label="UF"
                          label-for="endereco"
                        >
                          <b-form-input
                            id="endereco"
                            v-model="customer.uf"
                            placeholder="UF"
                            trim
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <b-form-group
                          label="Bairro"
                          label-for="endereco"
                        >
                          <b-form-input
                            id="BAIRRO"
                            v-model="customer.bairro"
                            placeholder="Bairro"
                            trim
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <b-form-group
                          label="Cidade"
                          label-for="country"
                        >
                          <b-form-input
                            id="endereco"
                            v-model="customer.cidade"
                            placeholder="Cidade"
                            trim
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-row>
              </b-card-body>

              <!-- Card Produto --->
              <hr class="invoice-spacing">
              <b-card-body class="invoice-padding pt-0">
                <b-row>
                  <h6 class="mb-2">
                    Produto:
                  </h6>
                  <b-form>
                    <b-row>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <label>*Tipo de Garantia</label>
                        <v-select
                          v-model="produto.tipo_garantia"
                          :options="optionsTipoGarantia"
                          class="mb-2 item-selector-title"
                          placeholder="Selecionar Tipo de Garantia"
                        />
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <label class="d-inline">*Marca <span
                          class="nova-entidade"
                          @click="cadastrarNovaEntidade('marca')"
                        >+ Add Nova</span></label>
                        <v-select
                          v-model="produto.marca_id"
                          :options="optionsMarcas"
                          class="mb-2 item-selector-title"
                          placeholder="Selecionar Marca"
                        />
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <label class="d-inline">*Revenda <span
                          class="nova-entidade"
                          @click="cadastrarNovaEntidade('revenda')"
                        >+ Add Nova</span></label>
                        <v-select
                          v-model="produto.revenda_id"
                          :options="optionsRevendas"
                          class="mb-2 item-selector-title"
                          placeholder="Selecionar Revenda"
                        />
                      </b-col>
                      <b-col
                        v-if="produto.tipo_garantia === 'GARANTIA ESTENDIDA'"
                        md="6"
                        sm="12"
                      >
                        <label class="d-inline">*Seguradora <span
                          class="nova-entidade"
                          @click="cadastrarNovaEntidade('seguradora')"
                        >+ Add Nova</span></label>
                        <v-select
                          v-model="produto.seguradora_id"
                          :options="optionsSeguradora"
                          class="mb-2 item-selector-title"
                          placeholder="Selecionar Seguradora"
                        />
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <label class="d-inline">*Linha <span
                          class="nova-entidade"
                          @click="cadastrarNovaEntidade('linha')"
                        >+ Add Nova</span></label>
                        <v-select
                          v-model="produto.linha_id"
                          :options="optionsLinhas"
                          class="mb-2 item-selector-title"
                          placeholder="Selecionar Linha"
                        />
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <label class="d-inline">Produto </label>
                        <b-form-input
                          v-model="produto.nome"
                          class="mb-2"
                        />
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <label class="d-inline">Valor Produto</label>
                        <b-form-input
                          v-model="produto.valor_bruto"
                          type="text"
                          class="mb-2"
                          @change="real(produto.valor_bruto)"
                          @focus="removerString(produto.valor_bruto)"
                        />
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <label class="d-inline">Número Serie</label>
                        <b-form-input
                          v-model="produto.numero_serie"
                          class="mb-2"
                        />
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <label class="d-inline">Certificado</label>
                        <b-form-input
                          v-model="produto.certificado"
                          class="mb-2"
                        />
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <label class="d-inline">Modelo</label>
                        <b-form-input
                          v-model="produto.modelo"
                          class="mb-2"
                        />
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <label class="d-inline">Número NF</label>
                        <b-form-input
                          v-model="produto.numero_nf"
                          class="mb-2"
                        />
                      </b-col>
                      <b-col
                        md="6"
                        sm="12"
                      >
                        <label class="d-inline">Imei</label>
                        <b-form-input
                          v-model="produto.imei"
                          class="mb-2"
                        />
                      </b-col>
                    </b-row>
                  </b-form>
                </b-row>
              </b-card-body>

              <!-- Card atendente --->
              <hr class="invoice-spacing">
              <b-card-body class="invoice-padding pt-0">
                <b-row>
                  <b-col>
                    <h6 class="mb-2">
                      *Atendentes:
                    </h6>

                    <v-select
                      v-model="selectedAtendente"
                      :options="atendentes"
                    />
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Items Section -->
              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">Relato do Cliente: </span>
                <b-form-textarea v-model="invoice.relato_cliente" />
              </b-card-body>
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">Observação (Opcional): </span>
                <b-form-textarea v-model="invoice.observacao" />
              </b-card-body>
              <b-col
                md="4"
                offset-md="4"
                class="invoice-actions mt-md-0 mt-2"
                style="margin-bottom: 2%;"
              >
                <!-- Action Buttons -->
                <!-- Button: Print -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  block
                  @click="storeOs"
                >
                  Cadastrar
                </b-button>
              </b-col>
            </b-card>
          </b-form>
        </b-col>

      <!-- Right Col: Card -->

      </b-row>
    </section>
  </b-overlay>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  BRow, BCol, BCard, BCardBody, BButton, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, VBToggle, BInputGroupAppend, BModal, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import invoiceStoreModule from './invoiceStoreModule'
import axios from '../../../../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BFormGroup,
    // BCardText,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,
    flatPickr,
    vSelect,
    Logo,
    Cleave,
    BModal,
    VueAutosuggest,
    BOverlay,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }
    return {
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      load: false,
      novaEntidade: null,
      entidadeId: null,
      entidadeSelecionada: null,
      sugestao: false,
      optionsTipoGarantia: [
        'GARANTIA ESTENDIDA',
        'GARANTIA DE FÁBRICA',
        'FORA DE GARANTIA',
      ],
      optionsMarcas: [],
      optionsRevendas: [],
      optionsLinhas: [],
      optionsSeguradora: [],
      empresaID: null,
      nomeMarca: '',
      nomeLinha: '',
      nomeRevenda: '',
      empresaNome: null,
      item: {
        Marca: [],
        Linha: [],
        Revenda: [],
        Seguradora: [],
      },
      optionsAtendimento: [
        'NORMAL',
        'PRIORITÁRIO',
        'EMERGENCIAL',
        'DANOS ELÉTRICOS',
        'ASSISTÊNCIA FUNERAL',
        'REINCIDÊNCIA',
      ],
      atendentes: [],
      customer: {
        nome: null,
        telefone: null,
        email: null,
        endereco: null,
        cep: null,
        bairro: null,
        cidade: null,
        uf: null,
        numero: null,
        cpf_cnpj: '',
      },
      mask: {
        cep: {
          delimiters: ['-'],
          blocks: [5, 3],
        },
        cnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
        cpf: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 3],
          uppercase: true,
        },
      },
      produto: {
        marca_id: null,
        linha_id: null,
        revenda_id: null,
        seguradora_id: null,
        tipo_garantia: null,
        nome: null,
        valor_custo: null,
        valor_bruto: null,
        numero_serie: null,
        modelo: null,
        certificado: null,
        numero_nf: null,
        imei: null,
        garantia_fabrica: false,
      },
      invoice: {
        sinistro: this.gerarNumeroAleatorio(1000, 9000),
        data_abertura: null,
        tipo_atendimento: null,
        relato_cliente: null,
        observacao: null,
      },
      selectedAtendente: [],
      dataSuggest: [],
      filteredOptions: [],
      limit: 10,
      selected: null,
      searchQuery: '',
      suggestions: [],
      showSearchBar: false,
      dataResponse: [],
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    this.empresaNome = JSON.parse(localStorage.getItem('userData'))?.empresa
    this.getAtendentes()
    this.getSeguradoras()
    this.getLinhas()
    this.getMarcas()
    this.getRevendas()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    resetSearchQuery() {
      this.searchQuery = ''
    },
    async handleSearchInput(event) {
      this.searchQuery = event
      if (this.searchQuery.length === 3) {
        this.showSearchBar = true
        const response = await axios.post('api/cliente/listar/nome', { nome: this.searchQuery })
        const data = response.data.dados
        this.dataResponse = data
        this.suggestions = data
          .filter(item => item.nome.includes(this.searchQuery.toUpperCase()))
          .map(item => item.nome)
      }
      if (event === '') {
        this.suggestions = []
        this.resetSearchQuery()
        this.showSearchBar = false
      }
    },
    suggestionSelected(suggestion) {
      const data = this.dataResponse
      const objetoSelecionado = data.find(item => item.nome === suggestion)
      this.showSearchBar = false
      this.customer = {
        nome: objetoSelecionado.nome,
        telefone: objetoSelecionado.telefone,
        email: objetoSelecionado.email,
        endereco: objetoSelecionado.endereco,
        cep: objetoSelecionado.cep,
        bairro: objetoSelecionado.bairro,
        cidade: objetoSelecionado.cidade.nome,
        uf: objetoSelecionado.uf,
        numero: objetoSelecionado.numero,
        cpf_cnpj: this.cpfCNPJ(objetoSelecionado.cpf_cnpj),
      }
    },
    cadastrar() {
      if (this.entidadeSelecionada === 'marca') {
        this.cadastrarNovaMarca()
      }
      if (this.entidadeSelecionada === 'seguradora') {
        this.cadastrarNovaSeguradora()
      }
      if (this.entidadeSelecionada === 'linha') {
        this.cadastrarNovaLinha()
      }
      if (this.entidadeSelecionada === 'revenda') {
        this.cadastrarNovaRevenda()
      }
      this.novaEntidade = null
    },
    onSelected(item) {
      this.selected = item.item
    },
    cadastrarNovaMarca() {
      const body = {
        nome: this.nomeMarca,
        empresa_id: this.empresaID,
      }

      axios.post('api/marca/nova', body)
        .then(() => {
          this.getMarcas()
          this.$swal({
            icon: 'success',
            title: 'Cadastrado!',
            text: 'Marca cadastrada com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          if (error.response) {
            this.$swal({
              title: 'Erro ao cadastrar uma Marca!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    cadastrarNovaLinha() {
      const body = {
        nome: this.nomeLinha,
        empresa_id: this.empresaID,
      }

      axios.post('api/linha/nova', body)
        .then(() => {
          this.getLinhas()
          this.$swal({
            icon: 'success',
            title: 'Cadastrado!',
            text: 'Linha cadastrada com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          if (error.response) {
            this.$swal({
              title: 'Erro ao cadastrar uma Linha!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    cadastrarNovaRevenda() {
      const body = {
        nome: this.nomeRevenda,
        empresa_id: this.empresaID,
      }

      axios.post('api/revenda/nova', body)
        .then(() => {
          this.getRevendas()
          this.$swal({
            icon: 'success',
            title: 'Cadastrado!',
            text: 'Revenda cadastrada com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          if (error.response) {
            this.$swal({
              title: 'Erro ao cadastrar uma Revenda!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    cadastrarNovaSeguradora() {
      const body = {
        nome: this.nomeSeguradora,
        empresa_id: this.empresaID,
      }

      axios.post('api/seguradora/nova', body)
        .then(() => {
          this.getSeguradoras()
          this.$swal({
            icon: 'success',
            title: 'Cadastrado!',
            text: 'Seguradora cadastrada com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          if (error.response) {
            this.$swal({
              title: 'Erro ao cadastrar uma Seguradora!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    cadastrarNovaEntidade(entidade) {
      this.entidadeSelecionada = entidade
      /* await axios.get(`/api/${entidade}/`)
        .then(res => {
          res.data.dados.map(item => {
            if (!this.checkExisteEntidade(entidade, item.nome)) {
              this.dataSuggest.push({
                name: item.nome,
              })
            }
          })
        }) */
      this.$bvModal.show('cadastrar-nova-entidade')
    },
    checkExisteEntidade(entidade, nome) {
      if (entidade === 'marca') {
        const marca = this.optionsMarcas.find(obj => obj.label === nome)
        if (marca) {
          return true
        }
        return false
      } if (entidade === 'seguradora') {
        const seguradora = this.optionsSeguradora.find(obj => obj.label === nome)
        if (seguradora) {
          return true
        }
        return false
      }
      return null
    },
    real(value) {
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(value.replace('R$', '').replace(',', '.').trim())) {
        let valor = value.replace('R$', '').replace(',', '.').trim()
        valor = parseFloat(valor)
        const formatter = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
        this.produto.valor_bruto = formatter.format(valor)
      } else {
        this.produto.valor_bruto = 0
      }
    },
    removerString(value) {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(value.replace('R$', '').replace(',', '.').trim())) {
        this.produto.valor_bruto = 0
      } else {
        this.produto.valor_bruto = value.replace('R$', '').replace(',', '.').trim()
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form)
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    getAtendentes() {
      store.dispatch('app-invoice/fetchAtendentes')
        .then(response => {
          response.data.dados.map(item => {
            this.atendentes.push({
              label: item.name,
              id: item.id,
            })
          })
        })
    },
    async consultaCEP() {
      if (this.customer.cep) {
        if (this.customer.cep.replace(/[^0-9]/g, '').length === 8) {
          await axios
            .get(`https://viacep.com.br/ws/${this.customer.cep}/json/`)
            .then(async res => {
              const { data } = res
              if (data.erro) {
                this.$swal({
                  title: 'ERROR',
                  text: 'CEP não encontrado',
                  icon: 'error',
                  customClass: {
                    comfirmButton: 'btn btn-danger',
                  },
                })
              } else {
                this.customer.endereco = data.logradouro
                this.customer.bairro = data.bairro
                this.customer.cidade = data.localidade
                this.customer.uf = data.uf
              }
            })
            .catch(() => {
              this.$swal({
                title: 'ERROR',
                text: 'CEP não encontrado',
                icon: 'error',
                customClass: {
                  comfirmButton: 'btn btn-danger',
                },
              })
            })
        }
      }
      return null
    },
    getSeguradoras() {
      this.optionsSeguradora = []
      axios.get(`api/empresas/seguradoras/${this.empresaID}`).then(res => {
        res.data.dados.map(item => {
          this.optionsSeguradora.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
    getRevendas() {
      this.optionsRevendas = []
      axios.get(`api/empresas/revendas/${this.empresaID}`).then(res => {
        res.data.dados.map(item => {
          this.optionsRevendas.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
    getMarcas() {
      this.optionsMarcas = []
      axios.get(`api/empresas/marcas/${this.empresaID}`).then(res => {
        res.data.dados.map(item => {
          this.optionsMarcas.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
    getLinhas() {
      this.optionsLinhas = []
      axios.get(`api/empresas/linhas/${this.empresaID}`).then(res => {
        res.data.dados.map(item => {
          this.optionsLinhas.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
    storeOs() {
      this.load = true
      if (this.produto.tipo_garantia === 'GARANTIA ESTENDIDA' && !this.produto.seguradora_id) {
        this.load = false
        return this.$swal({
          title: 'Erro ao cadastrar  OS!',
          text: 'Campos Obrigatórios não informado',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      if (!this.invoice.data_abertura || !this.invoice.tipo_atendimento || !this.produto.marca_id
          || !this.produto.revenda_id || !this.produto.tipo_garantia
          || !this.produto.linha_id || !this.customer.nome || !this.customer.cpf_cnpj || !this.selectedAtendente) {
        this.load = false
        return this.$swal({
          title: 'Erro ao cadastrar  OS!',
          text: 'Campos Obrigatórios não informado',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }

      const body = {
        cliente: this.customer,
        produto: this.produto,
        atendente_id: this.selectedAtendente.id,
        direcionador_id: JSON.parse(localStorage.getItem('userData'))?.id,
        dadosOs: this.invoice,
      }

      if (this.produto.seguradora_id !== null) {
        body.produto.seguradora_id = this.produto.seguradora_id.id
      }

      body.produto.marca_id = this.produto.marca_id.id
      body.produto.revenda_id = this.produto.revenda_id.id
      body.produto.linha_id = this.produto.linha_id.id
      body.produto.tipo_garantia = this.produto.tipo_garantia
      if (this.produto.valor_bruto !== null) {
        body.produto.valor_custo = this.valorDataBase(this.produto.valor_bruto)
        body.produto.valor_bruto = this.valorDataBase(this.produto.valor_bruto)
      } else {
        body.produto.valor_custo = 0
        body.produto.valor_bruto = 0
      }
      body.cliente.cpf_cnpj = this.soNumero(this.customer.cpf_cnpj)
      body.cliente.cep = this.soNumero(this.customer.cep)
      body.dadosOs.empresa_id = this.empresaID
      axios.post('api/ordem_servico/store', body)
        .then(() => {
          this.clearFormOs()
          this.load = false
          this.$swal({
            icon: 'success',
            title: 'Cadastrado!',
            text: 'OS cadastrada com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          if (error.response) {
            this.load = false
            this.$swal({
              title: 'Erro ao cadastrar uma OS!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
      return null
    },
    clearFormOs() {
      this.customer = {
        nome: null,
        telefone: null,
        email: null,
        endereco: null,
        cep: null,
        bairro: null,
        cidade: null,
        uf: null,
        numero: null,
        cpf_cnpj: '',
      }
      this.produto = {
        tipo_garantia: null,
        marca_id: null,
        linha_id: null,
        revenda_id: null,
        seguradora_id: null,
        nome: null,
        valor_custo: null,
        valor_bruto: null,
        numero_serie: null,
        modelo: null,
        certificado: null,
        numero_nf: null,
        imei: null,
        garantia_fabrica: false,
      }
      this.invoice = {
        sinistro: this.gerarNumeroAleatorio(1000, 9000),
        data_abertura: null,
        tipo_atendimento: null,
        relato_cliente: null,
        observacao: null,
      }
      this.selectedAtendente = []
    },
    valorDataBase(valor) {
      const soNumero = valor.replace(/\D/g, '')
      return soNumero / 100
    },
    soNumero(valor) {
      return valor.replace(/\D/g, '')
    },
    gerarNumeroAleatorio(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min) + min)
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.dataSuggest.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
.nova-entidade {
  float: right;
  margin-right: 5px;
}

.nova-entidade:hover {
  color: blueviolet;
  cursor: pointer;
}
</style>
